import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';

function ProtectedRoute({ children }) {
  const location = useLocation();
  
  console.log('location', location);
  // Replace with your actual authentication check, e.g., using context, state, or localStorage
  var isAuthenticated = !!localStorage.getItem('idToken'); // Assuming you save an auth token in localStorage

  if ('/landing' !== location.pathname) {
    isAuthenticated = !!localStorage.getItem('jwtToken');// here we need to implement the token expiry check also
  }

  if (!isAuthenticated) {
    // If the user is not authenticated, redirect to the Authn page
    return <Navigate to="/" state={{ from: location }} />;
  }

  return children; // Render the requested component if authenticated
}

export default ProtectedRoute;
