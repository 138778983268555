import React from 'react';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import ProtectedRoute from './app/components/authn/ProtectedRoute';
import GoogleAuth from './app/components/authn/GoogleAuthn';
import Landing from './app/components/landing/Landing';
import UserSummary from './app/components/summary/UserSummary';
import CarouselCard from './app/components/React-Carousel/Carousel';
import ItemCard from './app/components/CardComponent/Card';
import 'antd/dist/reset.css'; // Ant Design reset styles
import './App.css';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        {/* Public Route */}
        <Route path="/" element={<Navigate to="/authn" />} />
        <Route path="/authn" element={<GoogleAuth />} />
        <Route path="/carouselcard" element={<CarouselCard/>} />        {/* // This will be moved to protected route now it is unprotected for developmemnt purpose only  */}
        <Route path="/card" element={<ItemCard/>} />        {/* // This will be moved to protected route now it is unprotected for developmemnt purpose only  */}

        {/* Protected Routes */}
        <Route path="/landing" element={<ProtectedRoute><Landing /></ProtectedRoute>} />
        <Route path="/summary" element={<ProtectedRoute><UserSummary /></ProtectedRoute>} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
