import { createStore ,applyMiddleware ,combineReducers} from "redux";


export default function configureStore() {
    
    const store=createStore(combineReducers({

    }))
   return store 
}
