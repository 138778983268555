import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './app/components/location/reportWebVitals';
import Root from './Root';
import './index.css';
import 'antd/dist/reset.css'; // Ant Design reset styles


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<Root />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
