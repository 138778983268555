import React, { useState } from 'react';
import { Form, Input, Button, DatePicker, Select, Typography, Row, Col, message } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import moment from 'moment'; 
import './Registration.css';

const { Title, Link } = Typography;
const { Option } = Select;

const Registration = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  // Handle form submission
  const onFinish = (values) => {
    message.success('Account created successfully!');
    console.log('Form Values:', values);
  };

  const handleRegistration = async (userType) => {
    setLoading(true);
    setError(null);
    try {
        var idToken = localStorage.getItem('idToken');
        const registrationRequest = {
            idToken,
            userType
        };
        const response = await fetch('https://api.desispicyexpress.com/express/api/v1/register/user', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(registrationRequest),
        });
        setLoading(false);
        if (!response.ok) {
              setLoading(false);
              console.error('Network response was not ok for JWT Token.');
              throw new Error('Network response was not ok for JWT Token.');
        }
        const data = await response.json();
        localStorage.setItem('jwtToken', JSON.stringify(data.payload));
        console.log('Authentication successful for JWT Token.');
        // Navigate to summary page
        navigate('/summary', { state: data.payload });
    } catch (error) {
          setLoading(false);
          console.error('Failed to authenticate user for JWT Token.', error);
          setError('Failed to authenticate user for JWT Token.');
    }
    
  };

  return (
    <div className="signup-container">
      <Row justify="center" align="middle" style={{ height: '100vh' }}>
        <Col xs={24} sm={18} md={12} lg={10} xl={8}>
          <div className="signup-box">
            <Title level={1} style={{ textAlign: 'center' }}>
              Register
            </Title>
            <Form
              form={form}
              layout="vertical"
              onFinish={onFinish}
              autoComplete="off"
              className="signup-form"
            >
              {/* Name Fields */}
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    name="firstName"
                    label="First Name"
                    rules={[{ required: true, message: 'Please enter your first name!' }]}
                  >
                    <Input placeholder="First name" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="lastName"
                    label="Last Name"
                    rules={[{ required: true, message: 'Please enter your last name!' }]}
                  >
                    <Input placeholder="Last name" />
                  </Form.Item>
                </Col>
              </Row>

              

              {/* Date of Birth */}
              <Form.Item
                name="dateOfBirth"
                label="Date of Birth"
                rules={[
                  { required: true, message: 'Please select your date of birth!' },
                ]}
              >
                <DatePicker
                  placeholder="Select Date"
                  style={{ width: '100%' }}
                  format="DD/MM/YYYY"
                  disabledDate={(current) => current && current > moment().endOf('day')} // Disable future dates
                />
              </Form.Item>

              {/* Gender */}
              <Form.Item
                name="gender"
                label="Gender"
                rules={[{ required: true, message: 'Please select your gender!' }]}
              >
                <Select placeholder="Select Gender">
                  <Option value="male">Male</Option>
                  <Option value="female">Female</Option>
                  <Option value="other">Other</Option>
                </Select>
              </Form.Item>

              {/* Create Account Button */}
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  block
                  className="create-account-button"
                  onClick={() => handleRegistration('1')}
                >
                  Create Customer Account
                </Button>
              </Form.Item>

              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  block
                  className="create-account-button"
                  onClick={() => handleRegistration('2')}
                >
                  Create Owner Account
                </Button>
              </Form.Item>
              
            </Form>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Registration;
