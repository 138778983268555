import React from 'react';
import { Rate } from 'antd';
import { Card } from 'antd';
import './Card.css';

const { Meta } = Card;

const ItemCard = () => (
  <div className="responsive-card-container">
    <Card
      className="custom-card"
      cover={
        <img
          alt="Restaurant Food"
          src="https://images.pexels.com/photos/70497/pexels-photo-70497.jpeg?auto=compress&cs=tinysrgb&w=600"
          className="card-image"
        />
      }
    >
      <Meta
        title={
          <div className="card-header">
            <span className="card-title">Swathi Mess</span>
            <div className="card-rating">
              <Rate
                allowHalf
                defaultValue={4.5}
                disabled
                className="ant-rate-custom"
              />
            </div>
          </div>
        }
        description={
          <>
            <p className="card-description">Delicious South Indian meals</p>
            <div className="card-type">
              <span className="veg-dot" /> Veg
            </div>
          </>
        }
      />
    </Card>
  </div>
);

export default ItemCard;
