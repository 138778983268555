import { Provider } from "react-redux";
import configureStore from "./app/store/configureStore";
import App from "./App";

// eslint-disable-next-line no-undef
 const Root = () => {
  const store = configureStore({});

  return (
    <Provider store={store}>
      <App />
    </Provider>
  );
};
export default Root;