// src/components/UserSummary.js
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import './UserSummary.css'; // Import the CSS file

const UserSummary = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [summaryData, setSummaryData] = useState([]);
  const location = useLocation();

  useEffect(() => {
    const fetchSummary = async () => {
      try {
        // Retrieve the token from localStorage
        const storedJwtToken = localStorage.getItem('jwtToken');
        
        if (storedJwtToken) {
          const jwtToken = JSON.parse(storedJwtToken);
          //This log need to be removed
          console.log('storedJwtToken', jwtToken);

          // Make the API request to fetch user summary data
          const response = await fetch('https://api.desispicyexpress.com/express/api/v1/user/summary', {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + jwtToken.accessToken
            }
          });

          if (!response.ok) {
            throw new Error('Network response was not ok for fetching User Summary data.');
          }

          const data = await response.json();
          setSummaryData(data.payload); // Update the summary data
          setLoading(false); // End loading state
          console.log('User Summary data fetched successfully', data);
        } else {
          console.log('Token data is not found in localStorage for fetching User Summary data.');
          setError('Token data is not found in localStorage.');
          setLoading(false); // End loading state
        }
      } catch (error) {
        setError('Failed to fetch User Summary data for JWT Token.');
        setLoading(false); // End loading state
        console.error('Error fetching User Summary data', error);
      }
    };

    fetchSummary(); // Fetch summary on component mount
  }, []); // Empty dependency array means this effect runs once when the component mounts

  if (loading) {
    return <div className="loading">Loading...</div>;
  }

  if (error) {
    return <div className="error">{error}</div>;
  }

  if (!summaryData.length) {
    return <div className="no-data">No summary data available.</div>;
  }

  return (
    <div className="summary-container">
      <h1>Summary Page</h1>
      {summaryData.map((user, index) => (
        <div key={index} className="user-card">
          <div className="user-info">
            <h2>User {index + 1} Information</h2>
            <p><strong>First Name:</strong> {user.firstName}</p>
            <p><strong>Last Name:</strong> {user.lastName}</p>
            <p><strong>Date of Birth:</strong> {user.dateOfBirth}</p>
            <p><strong>Gender:</strong> {user.gender}</p>
            <p><strong>Status:</strong> {user.status}</p>
            <p><strong>Distance:</strong> {user.distance}</p>
          </div>
          
          <div className="menu-items">
            <h3>Menu Items</h3>
            <ul className="menu-list">
              {user.menuItemDTOs.map((item, idx) => (
                <li key={idx} className="menu-item">
                  <p><strong>{item.itemName}</strong></p>
                  <p>{item.description}</p>
                  <p><strong>Price:</strong> ${item.price}</p>
                  <p><strong>Status:</strong> {item.status}</p>
                </li>
              ))}
            </ul>
          </div>
        </div>
      ))}
    </div>
  );
};

export default UserSummary;
