import React from 'react';
import { Carousel } from 'antd';
import './CarouselCard.css';

const CarouselCard = () => (
  <Carousel autoplay>
    <div>
      <img
        src="https://images.pexels.com/photos/376464/pexels-photo-376464.jpeg?auto=compress&cs=tinysrgb&w=800"
        alt="Restaurant Promotion 1"
        className="carousel-image"
      />
    </div>
    <div>
      <img
        src="https://images.pexels.com/photos/70497/pexels-photo-70497.jpeg?auto=compress&cs=tinysrgb&w=800"
        alt="Restaurant Promotion 2"
        className="carousel-image"
      />
    </div>
    <div>
      <img
        src="https://images.pexels.com/photos/1437267/pexels-photo-1437267.jpeg?auto=compress&cs=tinysrgb&w=800"
        alt="Restaurant Promotion 3"
        className="carousel-image"
      />
    </div>
    <div>
      <img
        src="https://images.pexels.com/photos/1565982/pexels-photo-1565982.jpeg?auto=compress&cs=tinysrgb&w=800"
        alt="Restaurant Promotion 4"
        className="carousel-image"
      />
    </div>
  </Carousel>
);

export default CarouselCard;
