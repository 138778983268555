import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import './GoogleAuthn.css'; 

const GoogleAuthn = () => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    const handleSuccess = async (credentialResponse) => {
        setLoading(true);
        setError(null);
        // 'credential' is the ID token from Google
        const { credential } = credentialResponse;
        //This log need to be removed
        console.log('Token:', credential);
        var idToken = credential;
        const authnReq = {
          idToken
      };

      try {
          const response = await fetch('https://api.desispicyexpress.com/express/api/v1/authn/token', {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/json',
              },
              body: JSON.stringify(authnReq),
          });
          setLoading(false);
          if (!response.ok) {
                setLoading(false);
                console.error('Network response was not ok.');
                throw new Error('Network response was not ok.');
          }
          const data = await response.json();
          localStorage.setItem('idToken', idToken);
          console.log('Authentication successful:', data);
          // Navigate to landing page
          navigate('/landing', { state: data.payload });
      } catch (error) {
            setLoading(false);
            console.error('Failed to authenticate user.', error);
            setError('Failed to authenticate user.');
      }
    };

    const handleError = () => {
        setLoading(false);
        console.error("Login Failed.");
        setError('Login Failed.');
    };

    return (
        <GoogleOAuthProvider clientId="369945347035-m9kkmbaa6i18p720m5qrjldbif2e63d3.apps.googleusercontent.com">
            <div className="google-oauth-container">
                <div className="login-box">
                    <h2>Welcome to Desi Spicy Express</h2>
                    <h3>To Continue Please</h3>
                    <GoogleLogin
                        onSuccess={handleSuccess}
                        onError={handleError}
                        render={({ onClick }) => (
                        <button
                            onClick={onClick}
                            className="google-login-button"
                        >
                            Sign in with Google
                        </button>
                        )}
                    />
                </div>
            </div>
        </GoogleOAuthProvider>
    );
};

export default GoogleAuthn;